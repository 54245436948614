import FormNewsletter from "../components/form-newsletter";
import Header from "../components/header";
import Footer from "../components/footer";

export default function Newsletter() {
  return (
    <>
      <Header />
      <main>
        <div className="container mx-auto border-b border-gray-200"></div>
        <section className="bg-white py-16">
          <div className="container mx-auto md:px-0 px-8">
            <div className="text-base leading-7 text-gray-700">
              <h1 className="pb-5 text-5xl font-semibold">
                Sign up for our mailing list
              </h1>
              <div className="w-20 border-b-4 border-red-600 mb-5"></div>
              <div className="md:flex gap-x-10">
                <article className="w-full md:w-6/12">
                  <p className="text-2xl">
                    You can sign up for our mailing list for all the latest
                    Third Way Investment Partners news and related information
                    by completing the subscription form.
                  </p>
                  <p className="mt-4 text-sm text-gray-500">
                    You can unsubscribe from our mailing list at any time using
                    the link in our emails. Learn about how we process your
                    personal data in our{" "}
                    <a className="hover:underline" href="/legal/privacy-policy">
                      privacy&nbsp;policy
                    </a>
                    .
                  </p>
                  <p className="mt-4 text-sm text-gray-500">
                    This form is protected by reCAPTCHA. Google's{" "}
                    <a
                      href="https://policies.google.com/privacy?hl=en-GB"
                      target="_blank"
                      className="hover:underline"
                    >
                      Privacy Policy
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://policies.google.com/terms?hl=en-GB"
                      target="_blank"
                      className="hover:underline"
                    >
                      Terms of Service
                    </a>{" "}
                    apply.
                  </p>
                </article>
                <div className="w-full md:w-6/12 mt-5 md:mt-0 ">
                  <FormNewsletter bgType={`light`} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
